<template>
  <div id="section7">
    <b-container fluid>
      <b-container>
        <div class="HeadContainer-small text-center">
          <div class="HeadVertical-center">
            <h2>
              {{ $t('homologacion.section7.title') }}
            </h2>
            <br>
            <div>
              <b-button
                v-scroll-to="'#contactForm'"
                class="buttonWhite-outline"
              >
                {{ $t('homologacion.section7.btn') }}
              </b-button>
            </div>
          </div>
        </div>
      </b-container>
    </b-container>
  </div>
</template>

<script>
export default {
    data() {
        return {
            urlLink: '#'
        };
    }
};
</script>

<style lang="scss">
#section7 {
    background: linear-gradient(rgb(0, 112, 168), rgb(0, 124, 124));
    color: whitesmoke;
}
</style>
