<template>
  <div id="homologacion">
    <Section1 />
    <Section2 />
    <Section3 />
    <Section4 />
    <Section5 />
    <Section6 />
    <Section7 />
  </div>
</template>

<script>
import Section1 from './components/homologacion/Section1';
import Section2 from './components/homologacion/Section2';
import Section3 from './components/homologacion/Section3';
import Section4 from './components/homologacion/Section4';
import Section5 from './components/homologacion/Section5';
import Section6 from './components/homologacion/Section6';
import Section7 from './components/homologacion/Section7';

export default {
    name: 'Homologacion',
    components: {
        Section1,
        Section2,
        Section3,
        Section4,
        Section5,
        Section6,
        Section7
    },
    head: {
        title: {
            inner: 'Homologar tu carrera en España'
        }
    }
};
</script>
<style scoped lang="scss">
@import '../../assets/css/stlProyecto.css';
</style>
