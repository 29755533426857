var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"sectionUni"}},[_c('b-container',{attrs:{"fluid":""}},[_c('b-container',{staticClass:"text-center"},[_c('br'),_c('h2',_vm._l((_vm.titles),function(item,idx){return _c('span',{key:idx},[(item.titleS)?_c('strong',[_vm._v(" "+_vm._s(item.title)+" ")]):_c('span',[_vm._v(" "+_vm._s(item.title)+" ")])])}),0),_c('div',{staticStyle:{"margin":"1em"}},[_c('carousel',{attrs:{"autoplay":true,"autoplay-timeout":2000,"autoplay-hover-pause":true,"loop":true,"per-page-custom":[
            [480, 2],
            [768, 3],
            [980, 5],
            [1500, 7]
          ]}},_vm._l((_vm.unis),function(item,idx){return _c('slide',{key:idx},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
                customClass: 'my-tooltip-class'
              }),expression:"{\n                customClass: 'my-tooltip-class'\n              }"}],staticStyle:{"padding":"1em"},attrs:{"title":item.uniTitle}},[_c('a',{staticClass:"if-image",attrs:{"href":item.uniUrl,"target":"_blank"}},[_c('b-img',{attrs:{"src":item.uniImg}})],1)])])}),1)],1),_c('br')])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }