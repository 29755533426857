<template>
  <div
    id="section5"
    class="fondoGrisClaro"
  >
    <b-container fluid>
      <b-container class="mt-4 mb-4">
        <h2>
          {{ $t('homologacion.section5.title') }}
        </h2>
        <ul>
          <li
            v-for="(item, index) in lists"
            :key="index"
          >
            {{ item }}
          </li>
        </ul>
      </b-container>
    </b-container>
  </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    computed: {
        lists() {
            return this.$t('homologacion.section5.lists');
        }
    }
};
</script>
