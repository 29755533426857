<template>
  <div
    id="section4"
    class="fondoGrisClaro"
  >
    <b-container fluid>
      <b-container class="mt-4 ">
        <h2>
          {{ $t('homologacion.section4.title') }}
        </h2>
        <br>
        <h3>
          {{ $t('homologacion.section4.subtitle') }}
        </h3>
        <ul>
          <li
            v-for="(item, index) in lists"
            :key="index"
          >
            {{ item }}
          </li>
        </ul>
      </b-container>
    </b-container>
  </div>
</template>
<script>
export default {
    computed: {
        lists() {
            return this.$t('homologacion.section4.lists');
        }
    }
};
</script>
