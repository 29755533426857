<template>
  <div id="sectionUni">
    <b-container fluid>
      <b-container class="text-center">
        <br>
        <h2>
          <span
            v-for="(item, idx) in titles"
            :key="idx"
          >
            <strong v-if="item.titleS">
              {{ item.title }}
            </strong>
            <span v-else>
              {{ item.title }}
            </span>
          </span>
        </h2>
        <div style="margin: 1em;">
          <carousel
            :autoplay="true"
            :autoplay-timeout="2000"
            :autoplay-hover-pause="true"
            :loop="true"
            :per-page-custom="[
              [480, 2],
              [768, 3],
              [980, 5],
              [1500, 7]
            ]"
          >
            <slide
              v-for="(item, idx) in unis"
              :key="idx"
            >
              <div
                v-b-tooltip="{
                  customClass: 'my-tooltip-class'
                }"
                style="padding: 1em;"
                :title="item.uniTitle"
              >
                <a
                  :href="item.uniUrl"
                  class="if-image"
                  target="_blank"
                >
                  <b-img :src="item.uniImg" />
                </a>
              </div>
            </slide>
          </carousel>
        </div>
        <br>
      </b-container>
    </b-container>
  </div>
</template>

<script>
export default {
    props: {
        titles: {
            default: () => [],
            type: Array
        },
        unis: {
            default: () => [],
            type: Array
        }
    },
    data() {
        return {
            slide: 0,
            sliding: null
        };
    }
};
</script>

<style lang="scss">
#sectionUni {
    .my-tooltip-class > div {
        font-size: 1.1em !important;
        font-weight: 500;
    }

    .if-image {
        filter: grayscale(100%);
        -webkit-filter: grayscale(100%);
        transition: filter 0.4s;
        -webkit-transition: -webkit-filter 1s;
    }

    .if-image:hover {
        filter: grayscale(0);
        -webkit-filter: grayscale(0);
        transition: filter 0.4s;
        -webkit-transition: -webkit-filter 1s;
    }
}
</style>
