<template>
  <div
    id="section2"
    class="fondoGrisClaro"
  >
    <b-container fluid>
      <b-container class="mt-4 mb-4 text-center">
        <b-row align-h="center">
          <b-col
            v-for="(item, index) in lists"
            :key="index"
            lg="4"
            md="6"
            sm="12"
          >
            <div
              v-scroll-to="item.ref"
              class="cardd p-3"
              data-aos="zoom-in"
            >
              <div class="redondo">
                <span>{{ item.sec }}</span>
              </div>
              <div class="p-4">
                <b-img :src="listsImg[index]" />
              </div>
              <h3>
                {{ item.title }}
              </h3>
            </div>
          </b-col>
        </b-row>
        <br>
      </b-container>
    </b-container>
  </div>
</template>

<script>
export default {
    data() {
        return {
            listsImg: [
                require('@/assets/images/vectores/registrate-completa.png'),
                require('@/assets/images/vectores/registrate-llamada.png'),
                require('@/assets/images/vectores/registrate-perfil.png')
            ]
        };
    },
    computed: {
        lists() {
            return this.$t('homologacion.section2.lists');
        }
    },
    methods: {
        menuClicked(itemName) {
            this.$router.push({ name: itemName });
        }
    }
};
</script>

<style lang="scss">
#section2 {
    .cardd {
        border: 1px solid rgba($color: #000000, $alpha: 0.2);
        background-color: rgba($color: #000000, $alpha: 0.1);
        margin-bottom: 1.5em;
        height: calc(90%);
        cursor: pointer;

        img {
            width: 7em;
            height: auto;
        }

        .redondo {
            position: relative;
            span {
                position: absolute;
                top: -2em;
                left: calc(50% - 1.5em);
                width: 3em;
                height: 3em;
                padding: 0.75em;
                font-weight: 800;
                background-color: #888888;
                color: whitesmoke;
            }
        }
    }

    .cardd:hover {
        transform: scale(1.05);
    }
}
</style>
